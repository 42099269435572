import React from 'react'
import { useState } from 'react';

import { TiThMenu } from 'react-icons/ti';
import { IoCloseSharp } from 'react-icons/io5';

import logo from '../img/logo.png';
import '../styles/components/header.sass';

function Header() {
  const [menu, setMenu] = useState(false);

  const menuToggle = () => {
    setMenu(!menu);
  }

  return (
    <header>
      <div className="header-container">
        <div className="logo-container">
          <img src={logo} alt="Logomarca WA" />
        </div>
        <div className="hamb-menu">
          <button type="button" onClick={menuToggle} className={menu ? "menu-open" : "menu-closed"}>{!menu ? <TiThMenu /> : <IoCloseSharp />}</button>
          <div className={menu ? "show" : ""}>
            <ul>
              <li><a href="#home" onClick={menuToggle}>Home</a></li>
              <li><a href="#sobre" onClick={menuToggle}>Sobre</a></li>
              <li><a href="#contato" onClick={menuToggle}>Contato</a></li>
            </ul>
          </div>
        </div>
        <div className="nav-container">
          <ul>
            <li><a href="#home">Home</a></li>
            <li><a href="#sobre">Sobre</a></li>
            <li><a href="#contato">Contato</a></li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header