/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { useRef, useState } from 'react';

import { FaPhone } from 'react-icons/fa6';
import { FaLinkedin } from 'react-icons/fa';
import { SiMaildotru } from 'react-icons/si';

import '../styles/components/contact.sass';

function Contact() {
  const [feedback, setFeedback] = useState(false);
  const textToCopyRef = useRef(null);
  const copyLinkRef = useRef(null);
  const wame = "https://wa.me/5541988330053";

  const handleCopy = async e => {
    e.preventDefault();
    const textToCopy = textToCopyRef.current.textContent;
    try {
      await navigator.clipboard.writeText(textToCopy);
      setFeedback(true);
      setTimeout(() => {
        setFeedback(false);
      }, 2000);
      console.log(textToCopy);
    } catch (error) {
      console.log("Erro ao copiar");
    }
  }

  return (
    <section id="contato">
      <div className="contact-card">
        <div className="info-card">
          <div className="icon">
            <a href={wame} target="_blank" rel="noreferrer"><FaPhone /></a>
          </div>
          <div>
            <h3>Telefone</h3>
            <p><a href={wame} target="_blank" rel="noreferrer">(41) 98833-0053</a></p>
          </div>
        </div>
        <div className="info-card">
          <div className="icon">
            <a href="#" ref={copyLinkRef} onClick={handleCopy}><SiMaildotru /></a>
          </div>
          <div>
            <h3>E-mail</h3>
            <p ref={textToCopyRef}><a ref={copyLinkRef} onClick={handleCopy}>contato@walberton.com.br</a></p>
          </div>
          {feedback && <div className='feedback'>E-mail copiado!</div>}
        </div>
        <div className="info-card" id='email'>
          <div className="icon"><a href="http://linkedin.com/walberton" target="_blank" rel="noreferrer"><FaLinkedin /></a></div>
          <div>
            <h3>LinkedIn</h3>
            <p><a href="http://linkedin.com/walberton" target="_blank" rel="noreferrer">linkedin.com/walberton</a></p>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Contact