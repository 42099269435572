import React from 'react'

import '../styles/components/banner.sass';

function Banner() {
  return (
    <section className="banner-container" id="home">
      <div className="banner-content">
        <div>
          <h2>Está pronto para levar seu negócio ao próximo nível?</h2>
          <p> Descubra como podemos transformar suas ideias em realidade digital. Dê o primeiro passo para o futuro da sua empresa!</p>
        </div>
        <a className="btn" href="#contato">Fale conosco</a>
      </div>
    </section>
  )
}

export default Banner