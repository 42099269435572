import React from 'react'

import design from '../img/circ-t.jpg';

import '../styles/components/promote.sass';

function Promote() {
  return (
    <section className="promo-container">
      <div className="promo-content">
        <h2>A W.Alberton está pronta para te ajudar a encontrar as soluções em tecnologia que você precisa.
        </h2>
        <img src={design} alt="" />
      </div>
    </section>
  )
}

export default Promote