import React from 'react'

import imagemAvance from '../img/Polygon-avance.jpg';

import '../styles/components/about.sass';

function About() {
  return (
    <section className="about-container" id="sobre">
      <div className="about-content">
        <h3>Atuando desde 2015 a W.Alberton tem oferecido serviços inovadores e de alta qualidade, expandindo seus projetos para diversas regiões do país. </h3>
        <img src={imagemAvance} alt='ilustração microchip'></img>
      </div>
      <div className="emphasis-container">
        <h3>Com foco em inovação e satisfação do cliente, se tornou referência em soluções web.</h3>
      </div>
    </section>
  )
}

export default About