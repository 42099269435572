import React from 'react'
import {FaTv , FaNetworkWired} from 'react-icons/fa';
import { PiShareNetworkFill } from "react-icons/pi";

import '../styles/components/questions.sass';

function Questions() {
  return (
    <section className="questions-container">
      <div className="text-container">
        <div className="first-quote display-quotes">
          <span><FaTv /></span>
          <p>Como o seu cliente te encontra? </p>
        </div>
        <div className="second-quote display-quotes">
          <p>Você está preparado para atender o seu cliente?</p>
          <span><FaNetworkWired /></span>
        </div>
        <div className="third-quote display-quotes">
          <span><PiShareNetworkFill /></span>
          <p>Sua empresa está integrada de forma eficiente?</p>
        </div>
      </div>
    </section>
  )
}

export default Questions