import React from 'react'

import '../styles/components/footer.sass';

function Footer() {
  return (
    <section className="footer-container">
    </section>
  )
}

export default Footer