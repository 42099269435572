import React from 'react'

import Header from './Header';
import Footer from './Footer';
import Banner from './Banner';
import About from './About';
import Questions from './Questions';
import Promote from './Promote';
import Contact from './Contact';

import '../styles/components/layout.sass';

function Layout() {
  return (
    <main className="main-container">
      <Header />
      <Banner />
      <About />
      <Questions />
      <Promote />
      <Contact />
      <Footer />
    </main>
  )
}

export default Layout